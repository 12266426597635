// JavaScript Document
if(!window.console){
    window.console = {
        log:function(){}
    };
}

(function($) {
    $.exitwidgetplugin = {
        defaultsExitToolParam: {
            btnContainer: "_exitwidgetcontainer",
            HotelId:'' ,
            GroupId:'',//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
            langkey:"en",
            hotelname:"Hotel Chinmay",
            serverurl:"https://live.ipms247.com/",
            ispromotion:'',
            IsLayout:0,//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
        }
    };
	
	$.fn.extend({  
        exitwidgetplugin:function(bkExitConfig) {  
            var options = $.extend({}, $.exitwidgetplugin.defaultsExitToolParam, bkExitConfig);  
            options.btnContainer = this.attr("id");
            methods._GetDataContain(options);
        }
    });
       
    var methods = {
        test:'123',
            _checkCookies:function(opt){
                try{
                    if(('sessionStorage' in window))
                    {
						//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - Start
						if(opt.GroupId != '')
							var keynamepop='Popup_ExitAlert_'+opt.GroupId;
                        else
							var keynamepop='Popup_ExitAlert_'+opt.HotelId;
						//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - End
							
                        if(sessionStorage.getItem(keynamepop)!=null)
                        {
							//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - Start
							if(opt.GroupId != '')
								var keynamepop='Popup_ExitAlert_'+opt.GroupId;
							else
								var keynamepop='Popup_ExitAlert_'+opt.HotelId;
							//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - End
                        }
                        else
                        {
                            sessionStorage.setItem(keynamepop,false);
                            methods._getAlertContent(opt,1,keynamepop);
                        }
                    }
                }
                catch(err){
                      console.log('error message _checkCookies');
                      console.log(err);
                }
            },
            _GetDataContain:function(opt){
                try{
                    //Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - Start
                    if(opt.GroupId != '')
                    {
                        var getUrl=opt.serverurl+'booking/multiproperty_service.php';
                        var param='action=GetContain&lang_code='+opt.langkey+'&GroupCode='+opt.GroupId;
                    }//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - End
                    else
                    {
                        var getUrl=opt.serverurl+'booking/service.php';
                        var param='action=GetContain&lang_code='+opt.langkey+'&HotelId='+opt.HotelId+'&ispromotion='+opt.ispromotion;
                    }
                    
                    jQuery.ajax({
                           type: "POST",
                           url: getUrl,
                           data: param,
                           success: function(response) {
                                var res = JSON.parse(jQuery.trim(response));
                                var promotion_list = [];
                                
                                var ExitFlag = res.flag.split('<:>');
                                opt.IsLayout = res.IsLayout;//Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ]
                                if(ExitFlag[9] == 1)//Chinmay Gandhi - 1.0.53.61 - 13th Feb 2018 - Show popup scrolling wise
                                {
                                    _TrackId=res.TrackId;
                                    //Chinmay Gandhi - 1.0.53.61 - Promotion Tool - Start
                                    if(opt.ispromotion == 1)
                                    {
                                        var toolcontent = res.content.split('<:>');
                                        var cap1 = toolcontent[0];
                                        var cap2 = toolcontent[1];
                                        var cap3 = toolcontent[2];
                                        var cap4 = toolcontent[3];
                                        var content = toolcontent[4];
                                        var txtcolor = toolcontent[5];
                                        var btncolor = toolcontent[6];
                                    }
                                    else
                                    {
                                        var RealContent = res.content.split('<:>');
                                        var content = RealContent[0];
                                        var txtcolor = RealContent[1];
                                        var btncolor = RealContent[2];
                                    }
                                    //Chinmay Gandhi - 1.0.53.61 - Promotion Tool - End
                                    
                                    var MainContain = "<div class='popup' id='ExitToolPopup' data-popup='popup-1'>"+
                                                        "<div class='popup-inner promotional' style=' background: #f0f0f0 !important;padding: 30px 20px 20px !important;max-width: 550px;width: 90% !important; top:32%;'>"+
                                                            "<div style='margin: 0;padding: 0;' id='PreviewText'>"+content+"</div>"+
                                                            "<a class='popup-close' data-popup-close='popup-1'></a>"+
                                                        "</div>"+
                                                    "</div>";
                                    
                                    if(opt.GroupId != '')
                                        MainContain = MainContain.replace('{groupname}', jQuery.trim(res.GroupName));
                                    else
                                        MainContain = MainContain.replace('{hotelname}', jQuery.trim(opt.hotelname));        
                                                   
                                    if(opt.ispromotion == 1)
                                    {
                                        if(res.promotion.length > 0)
                                        {
                                            jQuery.each(res.promotion, function(index, item) {
                                                var discount_type = '';
                                                var valid_for = '';
        
                                                if(item.validfor != 'ALL')
                                                {
                                                    var rstring = ' ' +cap2+ ' ';
                                                    valid_for = item['validfor'].replace(",", rstring);
                                                }
                                                else
                                                    valid_for = cap3;
        
                                                if(item.type == 'PERCENTAGE')
                                                    discount_type = '%';
                                                else if(item.type == 'FLAT')
                                                    discount_type = res.bcurr;
        
                                                promotion_list.push('<tr style="box-shadow:1px 1px 1px #eeeded"><td style="width: 70%;padding:10px;"><p style="font-size: 18px;font-weight:bold;margin: 0;">'+item.promotionname+'</p><p style="font-size:12px;font-weight:bold;color:#e80f0f;padding-top: 5px;margin: 0;">'+cap1+' : '+valid_for+'</p></td><td style="width: 30%;"><div style="background:#ed1c24;border-radius:30px 0 0 30px;text-align:right;padding: 0 10px;"><span style="font-size:30px;font-weight:bold;color:#fff">'+parseInt(item.amount) + '</span><span style="font-size:14px;font-weight:bold;color:#fff;">' + discount_type + ' ' + cap4 +'</span></div></td></tr>');
                                            });
        
                                            var promo_content = '';
        
                                            jQuery.each(promotion_list, function(index, val) {
                                                promo_content+=val;
                                            });
        
                                            var PromotionTool = '<table align="center" cellspacing="0" height="24" width="100%"><tbody style="font-family: Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif;font-weight: 400;">'+promo_content+'</tbody></table>';
                                            
                                            MainContain = MainContain.replace('{PromotionList}', jQuery.trim(PromotionTool));
                                        }
                                        else
                                        {
                                            MainContain = '';
                                        }
                                    }
                                    
                                    //Chinmay Gandhi - 1.0.53.61 - 13th Feb 2018 - Start
                                    //Purpose: Show popup scrolling wise
                                    if(ExitFlag[7] == 1)
                                    {
                                        var i = 0;
                                        jQuery(window).on('scroll', function(){
                                            var s = $(window).scrollTop(),
                                                d = $(document).height(),
                                                c = $(window).height();
                                          
                                            var scrollPercent = Math.round((s / (d - c)) * 100);
                                            
                                            if(scrollPercent >= ExitFlag[8] && i == 0)
                                            {
                                                jQuery("#_exitwidgetcontainer").html(MainContain);
                                                jQuery('.exitbookbtn').css({'color': txtcolor});
                                                jQuery('.exitbookbtn').css({'background': btncolor});
                                                methods._checkCookies(opt);//Chinmay Gandhi - 1st Oct 2019 - Show popup session storage wise [ L2B_WelcomeTool ]
                                                methods._ClosePopup(opt);
                                                i++;
                                            }
                                        });
                                    }
                                    else
                                    {
                                        jQuery("#_exitwidgetcontainer").html(MainContain);
                                        jQuery('.exitbookbtn').css({'color': txtcolor});
                                        jQuery('.exitbookbtn').css({'background': btncolor});
                                        methods._checkCookies(opt);//Chinmay Gandhi - 1st Oct 2019 - Show popup session storage wise [ L2B_WelcomeTool ]
                                        methods._ClosePopup(opt);
                                    }
                                    //Chinmay Gandhi - 1.0.53.61 - 13th Feb 2018 - End
                                }
                           }
                    });
                }
                catch(err){
                      console.log('error message _GetDataContain');
                      console.log(err);
                }
            },
            _getAlertContent:function(opt,closealert,keynamepop){
                try{
                    if (closealert=='1')
                    {
                        var PopCounter=0;

                        $('html').mouseleave(function(e)
                        {
                            if(e.clientY < 0)
                            {
                                if (PopCounter==0)
                                {
                                    document.cookie = keynamepop+"=true";
                                                   
                                    $("[data-dismiss=modal]").trigger({ type: "click" });
                                    PopCounter++;
                                    jQuery('#ExitToolPopup').fadeIn(200);
                                    methods._RedirectToBooking(opt);//Chinmay Gandhi - 1st Oct 2019 - Show popup session storage wise [ L2B_WelcomeTool ]
                                }
                            }
                        });
                    }
                }
                catch(err){
                    console.log('error message _getAlertContent');
                    console.log(err);
                }
            },
            _RedirectToBooking:function(opt){
                try{
                    jQuery('.exitbookbtn').on('click', function(e){
                        jQuery("a.exitbookbtn").removeAttr("href");
                        
                        //Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - Start
                        if(opt.IsLayout == 1)
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+ opt.HotelId +'&BookingThrough=2&L2BTrackId=' + _TrackId+'&selectedLang='+opt.langkey+'&IsLayout='+opt.IsLayout,'_blank');
                        else if(opt.IsLayout == 2)
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+ opt.GroupId +'&BookingThrough=2&L2BTrackId=' + _TrackId+'&selectedLang='+opt.langkey+'&IsLayout='+opt.IsLayout,'_blank');
                        else
                            window.open(opt.serverurl+'booking/roomwisedata.php?hid='+ opt.HotelId +'&BookingThrough=2&L2BTrackId=' + _TrackId,'_blank');
                        //Chinmay Gandhi - 12th July 2019 - L2B in MHBE [ RES-2099 ] - End
                    });
                }
                catch(err){
                    console.log('error message _RedirectToBooking');
                    console.log(err);
                }
            },
            _ClosePopup:function(opt){
                jQuery('.popup-close').on('click', function(e)  {
                    jQuery('#ExitToolPopup').fadeOut(200);
                });
            }
    }
       	
})(jQuery); 
